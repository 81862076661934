<template>
	<div class="animated fadeIn p-3">
		<div class="row">
			<div class="col">
				<b-button
					:variant="!showFilters ? 'primary' : 'secondary'"
					class="btn mx-1 bg-primary-alt"
					@click="showFilters = !showFilters">
					<i
						class="fa fa-filter"
						aria-hidden="true" />
				</b-button>
				<b-button
					:disabled="loadingDownload"
					class="float-right"
					variant="success"
					@click="downloadReport">
					<i :class="!loadingDownload ? 'fas fa-file-excel' : 'fas fa-spinner fa-spin'" /> {{ translate('export') }}
				</b-button>
				<div class="btn btn-lg">
					{{ datePeriod }}
				</div>
			</div>
		</div>
		<hr>
		<data-filter
			:display="showFilters"
			get-by="name"
			@submit="getDataFiltered"
			@clear="clearFilters">
			<template slot="form">
				<div class="row">
					<div
						class="col-sm">
						<search
							v-model="filters.user_id"
							:init-value="$route.query.user_id"
							:label-text="translate('autocomplete_label')"
							:endpoint="endpoint"
							:empty-result-message="translate('data_not_found')"
							:placeholder="'autocomplete_placeholder'"
							:default-params="{role:'distributor'}"
							class="mb-3"
							q="label"
							return-key="id" />
					</div>
					<div class="col-sm">
						<div
							class="form-group">
							<label
								for="dateRange"
								class="label">{{ translate('date_range') }}</label>
							<select
								id="dateRange"
								v-model="dateRange"
								name="dateRange"
								class="form-control">
								<option
									v-for="dateRange in dateRanges"
									:key="dateRange"
									:value="dateRange">
									{{ translate(dateRange) }}
								</option>
							</select>
						</div>
					</div>
					<div
						v-if="dateRange === dateRanges.custom"
						class="col-sm">
						<div
							class="form-group">
							<label
								for="startDate"
								class="label">{{ translate('start_date') }}</label>
							<v-date-picker
								id="startDate"
								v-model="startDate"
								:input-props="{ class: 'form-control' }"
								:formats="calendarConfig"
								name="startDate"
								show-caps />
						</div>
					</div>
					<div
						v-if="dateRange === dateRanges.custom"
						class="col-sm">
						<div
							class="form-group">
							<label
								for="endDate"
								class="label">{{ translate('end_date') }}</label>
							<v-date-picker
								id="endate"
								v-model="endDate"
								:input-props="{ class: 'form-control' }"
								:formats="calendarConfig"
								name="endDate"
								show-caps />
						</div>
					</div>
				</div>
			</template>
		</data-filter>
		<div class="table-responsive">
			<table class="table table-hover table-striped text-nowrap">
				<thead>
					<tr>
						<th>
							#
						</th>
						<th>
							{{ translate('user_id') }}
						</th>
						<th>
							{{ translate('username') }}
						</th>
						<th>
							{{ translate('name') }}
						</th>
						<th
							class="text-center">
							{{ translate('country') }}
						</th>
						<th
							class="text-right pointer"
							@click="sortByField('total_trip_points')">
							{{ translate('trip_points') }}
							<sort
								:import-data="sortData"
								field="total_trip_points" />
						</th>
					</tr>
				</thead>
				<tbody v-if="!loading && hasData">
					<tr
						v-for="(item, index) in data"
						:key="index"
						class="text-nowrap">
						<td class="align-middle">
							{{ ((pagination.per_page * pagination.current_page) - pagination.per_page) + (index + 1) }}
						</td>
						<td class="align-middle">
							<span
								class="pointer badge badge-primary"
								@click="toDashboard({ distributorId: item.attributes.user_id.toString() })">{{ item.attributes.user_id }}
							</span>
						</td>
						<td class="align-middle">
							{{ item.attributes.username }}
						</td>
						<td class="align-middle">
							{{ item.attributes.first_name }} {{ item.attributes.last_name }}
						</td>
						<td class="text-center">
							<img
								v-b-tooltip.hover
								:title="translate(item.attributes.country_code.toLowerCase())"
								:src="getFlag(item.attributes.country_code, true)"
								:alt="translate(item.attributes.country_code.toLowerCase())">
						</td>
						<td class="align-middle text-right">
							{{ item.attributes.total_trip_points }}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:has-data="hasData" />
		<b-pagination
			v-if="pagination.total_pages > 1"
			v-model="pagination.current_page"
			:total-rows="pagination.total"
			:per-page="pagination.per_page"
			align="center"
			@change="getDataFiltered" />
	</div>
</template>
<script>
import {
	PersonalBusinessVolume as PBV, Grids, Countries,
} from '@/translations';
import FiltersParams from '@/mixins/FiltersParams';
import DashboardRedirect from '@/mixins/DashboardRedirect';
import {
	PERIOD_FILTERS as dateRanges,
	V_CALENDAR_CONFIG as calendarConfig,
	DATE_START_FILTER as dateStartFilter,
	DATE_END_FILTER as dateEndFilter,
	MDY_FORMAT as mdyFormat,
	custom,
	allTime, YMD_FORMAT,
} from '@/settings/Dates';
import DataFilter from '@/components/DataFilter';
import Sort from '@/components/SortArrow';
import Reports from '@/util/Report';
import { SEARCH_USERS } from '@/config/endpoint';
import WindowSizes from '@/mixins/WindowSizes';
import { PAGINATION } from '@/settings/RequestReply';

const { endpoint } = SEARCH_USERS;
export default {
	name: 'TripPoints',
	messages: [PBV, Grids, Countries],
	components: {
		DataFilter,
		Sort,
	},
	mixins: [FiltersParams, DashboardRedirect, WindowSizes],
	data() {
		return {
			alerts: new this.$Alert(),
			dateRanges,
			calendarConfig,
			mdyFormat,
			custom,
			allTime,
			endpoint,
			rangeStart: '',
			rangeEnd: '',
			sortData: {},
			tripPointsReport: new Reports(),
			downloadTripPointsReport: new Reports(),
		};
	},
	computed: {
		loading() {
			return !!this.tripPointsReport.data.loading;
		},
		loadingDownload() {
			return !!this.downloadTripPointsReport.loading;
		},
		data() {
			try {
				const { data } = this.tripPointsReport.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		hasData() {
			const response = this.data.length;
			return !!response;
		},
		datePeriod() {
			return this.getDatePeriod();
		},
		pagination() {
			return this.tripPointsReport.data.response.data.meta.pagination;
		},
	},
	mounted() {
		this.tripPointsReport.getTripPointsReport().then(() => {
			const { start, end } = this.tripPointsReport.data.response.data.meta.period_dates;
			this.rangeStart = this.$moment(start.date).format(mdyFormat);
			this.rangeEnd = this.$moment(end.date).format(mdyFormat);
		});
		this.datesConfig();
	},
	methods: {
		datesConfig() {
			this.rangeStart = '';
			this.rangeEnd = '';
			delete this.dateRanges.all_time;
			delete this.dateRanges.custom;
			this.dateRanges.all_time = this.allTime;
			this.dateRanges.custom = this.custom;
			const { query } = this.$route;
			const dateKeys = Object.keys(query);
			if (typeof this.dateRanges[query.dateRange] !== 'undefined') {
				this.dateRange = query.dateRange;
			} else if (typeof query.dateRange !== 'undefined' && !Object.values(this.dateRanges).includes(query.dateRange)) {
				this.dateRange = this.dateRanges.this_month;
				query.dateRange = this.dateRanges.this_month;
			} else if (dateKeys.includes(dateStartFilter) || dateKeys.includes(dateEndFilter)) {
				this.dateRange = this.dateRanges.custom;
			} else if (typeof query.dateRange === 'undefined') {
				this.dateRange = this.dateRanges.this_month;
			}
			return null;
		},
		getDataFiltered(page) {
			const { query } = this.$route;
			const options = { ...query, ...this.filters };
			if (typeof page === 'number') {
				options[PAGINATION] = page;
			}
			this.tripPointsReport.getTripPointsReport(options);
		},
		getDatePeriod() {
			const start = this.rangeStart;
			const end = this.rangeEnd;
			if (start !== '' && end !== '') {
				return this.translate('date_period', { start, end });
			}

			if (start !== '') {
				return this.translate('date_period_from', { start });
			}

			if (end !== '') {
				return this.translate('date_period_until', { end });
			}
			return '';
		},
		getFlag(src, isSmall) {
			let path = 'header';
			if (isSmall === true) {
				path = 'select';
			}
			try {
				/* eslint-disable global-require */
				/* eslint-disable import/no-dynamic-require */
				return require(`@/assets/images/common/countries/${path}/${src}.png`);
			} catch (error) {
				return require(`@/assets/images/common/countries/${path}/default.png`);
			}
		},
		downloadReport() {
			this.alerts.loading(this.translate('loading'), this.translate('loading_text'), { allowOutsideClick: false, allowEscapeKey: false });
			const options = { ...this.query };
			this.downloadTripPointsReport.downloadTripPointsReport(options).then(() => {
				const link = document.createElement('a');
				link.href = window.URL.createObjectURL(this.downloadTripPointsReport.data.response.data);
				link.setAttribute('download', `${this.translate('trip_points')} - ${this.$moment().format(YMD_FORMAT)}.xlsx`);
				this.$el.appendChild(link);
				link.click();
			}).catch(() => {
			}).finally(() => {
				this.alerts.close();
			});
		},
	},
};
</script>
